<template>
  <div id="privacy-policy">
    <loading :can-cancel="true"></loading>
    <div class="privacy-police-container">
      <div class="content-container">
        <div class="header">{{ data ? data.header : "****" }}</div>
        <div class="content">{{ data ? data.content : "****" }}</div>
      </div>
      <div class="image">
        <img src="../assets/images/GDPR-amico.svg" alt="" />
      </div>
    </div>
    <div class="footer-wrapper">
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: { Footer, Loading },
  data() {
    return {
      data: {}
    };
  },
  mounted() {
    this.getPrivacyPolicy();
  },
  methods: {
    async getPrivacyPolicy() {
      let loader = this.$loading.show();
      try {
        let response = await axios("privacy-policy");
        this.data = response.data;
        loader.hide();
        console.log(this.data);
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      loader.hide();
    }
  }
};
</script>
<style scoped>
.privacy-police-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  margin-top: 70px;
}

.content-container {
  width: 100%;
}

.header {
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #070d59;
  margin-top: 32px;
  font-family: "Vazir Medium FD";
}

.content {
  margin-top: 27px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.image {
  display: none;
  width: 40%;
}

@media (min-width: 768px) {
  .privacy-police-container {
    padding: 0 70px;
  }

  .content {
    text-align: right;
  }
}

@media (min-width: 960px) {
  .content-container {
    width: 60%;
  }

  .image img {
    width: 100%;
  }

  .image {
    display: block;
    width: 40%;
  }

  .privacy-police-container {
    margin-top: 0;
  }
}
</style>
